import Welcome from 'pages/welcome'
import CountryOfResidencePage from 'pages/country-of-residence/index'
import RegisterEntity from 'pages/register-entity'
import Terms from 'pages/Terms/terms'
import VerifyIdentityPage from 'pages/verify-identity'
import TaxIdPage from 'pages/taxId'
import RegisterLiquidPage from 'pages/register-liquid'
import CountryNotAllowed from 'pages/country-of-residence/country-not-allowed'
import ActivateUser from 'pages/activate-user'
import Oops from 'pages/oops'
import ResendActivationEmail from 'pages/resend-activation-email'

const SignupFlowRoutes = [
  {
    path: '/activateUser',
    element: <ActivateUser />,
  },
  {
    path: '/activateUser/:token',
    element: <ActivateUser />,
  },
  {
    path: '/oops',
    element: <Oops />,
  },
  {
    path: '/resend-activation-email',
    element: <ResendActivationEmail />,
  },
  {
    path: '/welcome',
    element: <Welcome />,
  },
  {
    path: '/terms',
    element: <Terms />,
  },
  {
    path: '/register-entity',
    element: <RegisterEntity />,
  },
  {
    path: '/country-of-residence',
    element: <CountryOfResidencePage />,
  },
  {
    path: '/country-not-allowed',
    element: <CountryNotAllowed />,
  },
  {
    path: '/verify-identity',
    element: <VerifyIdentityPage />,
  },
  {
    path: '/taxid',
    element: <TaxIdPage />,
  },

  {
    path: '/register-liquid-securities',
    element: <RegisterLiquidPage />,
  },
]

export default SignupFlowRoutes
