import React, { useContext } from 'react'
import Text from 'components/Text/Text.styles'
import {
  Button,
  TextButton,
  Background,
  FAQ,
  SectionTitle,
  ComponentWrapper,
  Row,
  Column,
  AuthContext,
} from '@stokr/components-library'
import backgroundImage from 'static/images/covers/rocket.png'
import { DisplayOnBreakpoint } from 'styles/rwd'
import { UserTypes } from 'constants/enums'

const CountryNotAllowed = ({ selectedCountry, setIsCountryNotAllowed }) => {
  const { user } = useContext(AuthContext)
  const { user_type } = user
  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background src={backgroundImage} mobileRatio="16:9" />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        <ComponentWrapper noPaddingVertical>
          <TextButton onClick={() => setIsCountryNotAllowed(false)}>
            Go to Country of{' '}
            {user_type === UserTypes.investor_entity
              ? 'Registration'
              : 'Residence'}
          </TextButton>
        </ComponentWrapper>

        <ComponentWrapper>
          <Text>
            <h3>
              {user_type === UserTypes.investor_entity
                ? 'INVESTING VIA STOKR IS NOT YET AVAILABLE FROM WHERE YOUR ENTITY IS REGISTERED'
                : 'INVESTING VIA STOKR IS NOT YET AVAILABLE FROM WHERE YOU LIVE'}
            </h3>
            <p>
              We'll let you know as soon as we're {selectedCountry}-compliant
              too so you can start to invest. Meanwhile, you are still more than
              welcome to join our community!
            </p>
          </Text>
        </ComponentWrapper>

        <ComponentWrapper noPaddingTop>
          <a href="https://stokr.io/">
            <Button>EXPLORE OUR COMMUNITY</Button>
          </a>
        </ComponentWrapper>

        <ComponentWrapper noPaddingBottom>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
        </ComponentWrapper>

        <ComponentWrapper>
          <FAQ
            items={[
              {
                title: 'Why is STOKR not available in my country?',
                content:
                  'Your residence location determines the rules for investors. Generally, STOKR works with European capital market rules, so investing from a European member state is often possible. However, some countries impose stricter rules compared to the EU and makes it harder to invest in innovative projects. We work hard to look into each country rules and regulations but sometimes they are not friendly enough and we have to close them. Sorry for that.',
              },
            ]}
          />
        </ComponentWrapper>
      </Column>
    </Row>
  )
}

export default CountryNotAllowed
